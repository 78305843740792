<template>
  <div id="materialReportCertificate">
    <el-dialog
      :title="materialReportCertificateFormTitle"
      width="680px"
      :visible.sync="materialReportCertificateDialogVisible"
      :close-on-click-modal="false"
      @close="materialReportCertificateDialogClose"
    >
      <el-form
        ref="materialReportCertificateFormRef"
        :model="materialReportCertificateForm"
        :rules="materialReportCertificateFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品(物料)名称" prop="materialName">
              <el-input v-model="materialReportCertificateForm.materialName" placeholder="请输入产品(物料)名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产批号" prop="batchNum">
              <el-input v-model="materialReportCertificateForm.batchNum" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批量" prop="batchSize">
              <el-input v-model="materialReportCertificateForm.batchSize" placeholder="请输入批量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="materialReportCertificateForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请验日期" prop="checkDate">
              <el-date-picker v-model="materialReportCertificateForm.checkDate" placeholder="请选择请验日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="materialReportCertificateForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验依据" prop="basis">
              <el-input v-model="materialReportCertificateForm.basis" placeholder="请输入检验依据" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="外观：外观整洁，无破损，整批色泽均一，无明显色差，印刷清晰" prop="appearance" label-width="460px">
              <el-radio-group v-model="materialReportCertificateForm.appearance">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="材质：材质符合质量要求，与标准样版一致" prop="materialQuality" label-width="460px">
              <el-radio-group v-model="materialReportCertificateForm.materialQuality">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="印刷：内容、排版及颜色与样板一致" prop="printing" label-width="460px">
              <el-radio-group v-model="materialReportCertificateForm.printing">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="尺寸：尺寸大小符合要求，打印留白处符合要求，误差±1mm" prop="size" label-width="460px">
              <el-radio-group v-model="materialReportCertificateForm.size">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验结论" prop="conclusion">
              <el-radio-group v-model="materialReportCertificateForm.conclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="materialReportCertificateForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="materialReportCertificateForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reporterDate">
              <el-date-picker v-model="materialReportCertificateForm.reporterDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="materialReportCertificateForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reviewerDate">
              <el-date-picker v-model="materialReportCertificateForm.reviewerDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="materialReportCertificateForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="approverDate">
              <el-date-picker v-model="materialReportCertificateForm.approverDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="materialReportCertificateDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialReportCertificateFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品(物料)名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入产品(物料)名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialReportCertificatePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="产品(物料)名称" />
      <el-table-column prop="batchNum" label="生产批号" />
      <el-table-column prop="batchSize" label="批量" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column label="请验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkDate">{{ scope.row.checkDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="basis" label="检验依据" />
      <el-table-column label="外观">
        <template slot-scope="scope">
          <span v-if="scope.row.appearance === 0">不合格</span>
          <span v-if="scope.row.appearance === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="材质">
        <template slot-scope="scope">
          <span v-if="scope.row.materialQuality === 0">不合格</span>
          <span v-if="scope.row.materialQuality === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="印刷">
        <template slot-scope="scope">
          <span v-if="scope.row.printing === 0">不合格</span>
          <span v-if="scope.row.printing === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="尺寸">
        <template slot-scope="scope">
          <span v-if="scope.row.size === 0">不合格</span>
          <span v-if="scope.row.size === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="检验结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reporterDate">{{ scope.row.reporterDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{ scope.row.approverDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialReportCertificatePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaterialReport, deleteMaterialReport, updateMaterialReport, selectMaterialReportInfo, selectMaterialReportList } from '@/api/quality/materialReport'

export default {
  data () {
    return {
      materialReportCertificateDialogVisible: false,
      materialReportCertificateFormTitle: '',
      materialReportCertificateForm: {
        id: '',
        materialName: '',
        batchNum: '',
        batchSize: '',
        spec: '',
        checkDate: '',
        reportDate: '',
        basis: '',
        appearance: '',
        materialQuality: '',
        printing: '',
        size: '',
        conclusion: '',
        remarks: '',
        reporter: '',
        reporterDate: '',
        reviewer: '',
        reviewerDate: '',
        approver: '',
        approverDate: '',
        type: 3
      },
      materialReportCertificateFormRules: {
        materialName: [{ required: true, message: '产品(物料)名称不能为空', trigger: ['blur', 'change']}]
      },
      materialReportCertificatePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: '',
        type: 3
      }
    }
  },
  created () {
    selectMaterialReportList(this.searchForm).then(res => {
      this.materialReportCertificatePage = res
    })
  },
  methods: {
    materialReportCertificateDialogClose () {
      this.$refs.materialReportCertificateFormRef.resetFields()
    },
    materialReportCertificateFormSubmit () {
      if (this.materialReportCertificateFormTitle === '合格证原料检验报告书详情') {
        this.materialReportCertificateDialogVisible = false
        return
      }
      this.$refs.materialReportCertificateFormRef.validate(async valid => {
        if (valid) {
          if (this.materialReportCertificateFormTitle === '新增合格证原料检验报告书') {
            await addMaterialReport(this.materialReportCertificateForm)
          } else if (this.materialReportCertificateFormTitle === '修改合格证原料检验报告书') {
            await updateMaterialReport(this.materialReportCertificateForm)
          }
          this.materialReportCertificatePage = await selectMaterialReportList(this.searchForm)
          this.materialReportCertificateDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialReportCertificateFormTitle = '新增合格证原料检验报告书'
      this.materialReportCertificateDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialReport(row.id)
        if (this.materialReportCertificatePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialReportCertificatePage = await selectMaterialReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialReportCertificateFormTitle = '修改合格证原料检验报告书'
      this.materialReportCertificateDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialReportCertificateFormTitle = '合格证原料检验报告书详情'
      this.materialReportCertificateDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    selectMaterialReportInfoById (id) {
      selectMaterialReportInfo(id).then(res => {
        this.materialReportCertificateForm.id = res.id
        this.materialReportCertificateForm.materialName = res.materialName
        this.materialReportCertificateForm.batchNum = res.batchNum
        this.materialReportCertificateForm.batchSize = res.batchSize
        this.materialReportCertificateForm.spec = res.spec
        this.materialReportCertificateForm.checkDate = res.checkDate
        this.materialReportCertificateForm.reportDate = res.reportDate
        this.materialReportCertificateForm.basis = res.basis
        this.materialReportCertificateForm.appearance = res.appearance
        this.materialReportCertificateForm.materialQuality = res.materialQuality
        this.materialReportCertificateForm.printing = res.printing
        this.materialReportCertificateForm.size = res.size
        this.materialReportCertificateForm.conclusion = res.conclusion
        this.materialReportCertificateForm.remarks = res.remarks
        this.materialReportCertificateForm.reporter = res.reporter
        this.materialReportCertificateForm.reporterDate = res.reporterDate
        this.materialReportCertificateForm.reviewer = res.reviewer
        this.materialReportCertificateForm.reviewerDate = res.reviewerDate
        this.materialReportCertificateForm.approver = res.approver
        this.materialReportCertificateForm.approverDate = res.approverDate
        this.materialReportCertificateForm.type = res.type
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportCertificatePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportCertificatePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportCertificatePage = res
      })
    }
  }
}
</script>

<style>
</style>
